



































































































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Debounce } from "vue-debounce-decorator";

@Component
export default class Fleteros extends Vue {
  private unidades: any = null;
  private text: string = "";
  private busqueda: any = null;
  private selected: any = null;
  private verFletes: boolean = false;

  @Watch("text")
  private watchText(newValue: any) {
    this.buscar();
  }

  private mounted() {
    this.getFletes();
  }

  @Debounce(500)
  private buscar() {
    if (this.text.trim().length > 1) {
      let result: any = [];
      for (let i = 0; i < this.unidades.length; i++) {
        let unidad = this.unidades[i];
        let contiene = false;
        if (unidad.nombre.toUpperCase().indexOf(this.text.toUpperCase()) >= 0) {
          contiene = true;
        }
        if (contiene) {
          result.push(unidad);
        }
      }
      if (result.length > 0) {
        this.busqueda = result;
        this.selected = this.busqueda[0];
      } else {
        this.busqueda = null;
      }
    } else if (this.text.trim() == "") {
      this.getFletes();
    }
  }

  private getFletes() {
    this.$q.loading.show({
      delay: 100 // ms
    });

    this.$axios
      .get("fletes")
      .then((res: any) => {
        this.busqueda = res.data;
        this.unidades = res.data;
        this.selected = this.busqueda[0];
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.unidades = null;
        this.$q.loading.hide();
      });
  }

  private getStyle(id: any) {
    if (id == this.selected.id) {
      return "border-bottom: 1px solid grey;background-color:#f1f1f1;";
    }
    return "border-bottom: 1px solid grey;";
  }

  private mostrarDatos(id: any) {
    for (let i = 0; i < this.busqueda.length; i++) {
      const element = this.busqueda[i];
      if (element.id == id) {
        this.selected = element;
        break;
      }
    }
    if (this.$q.screen.xs || this.$q.screen.sm) {
      this.verFletes = true;
    }
  }
}
