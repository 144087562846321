
















































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import FormValidator from "@/components/mixins/FormValidator.ts";
import { jsPDF } from "jspdf";
import VueRecaptcha from "vue-recaptcha";

interface SelectOption {
  value: number | any;
  label: string;
}

@Component({
  components: {
    VueRecaptcha
  }
})
export default class FormularioEsterilizacion extends Mixins(FormValidator) {
  private reservaCompleta = false;
  private validRut = false;
  private formattedRut = "";
  private reservaUsuario: any = {
    rut: "",
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    email: "",
    telefono_1: "",
    telefono_2: "",
    comuna: "Punta Arenas",
    direccion: "",
    nombre_mascota: "",
    edad_mascota: "",
    sexo_mascota: null,
    raza_mascota: "",
    tipo_mascota: null
  };
  private bTerminos = false;
  private customModel = "no";

  private sexoMascotaOptions: Array<SelectOption> = [
    { value: 1, label: "Macho" },
    { value: 2, label: "Hembra" }
  ];
  private selectSexoMascota: SelectOption = {
    value: null,
    label: "Seleccione una opción"
  };

  private especieOptions: Array<SelectOption> = [
    { value: 1, label: "Canino" },
    { value: 2, label: "Felino" }
  ];
  private selectEspecie: SelectOption = {
    value: null,
    label: "Seleccione una opción"
  };
  private comunaOptions: SelectOption[] = [];
  private cityList: any[] = [];
  private dataComuna: SelectOption = {
    value: 338,
    label: "Punta Arenas"
  };
  private cupoFelinos = true;
  private cupoCaninos = true;
  private fechaVigente = true;

  @Watch("formattedRut")
  private formatRut(newValue: string) {
    newValue = newValue.trim();
    this.reservaUsuario.rut = newValue.replace(/\./g, "");
    newValue = newValue.replace(/\./g, "").replace(/-/g, "");
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.formattedRut = `${rut}-${dv}`;
    } else {
      this.formattedRut = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }
  @Watch("dataComuna")
  private comunaWatcher(newValue: any) {
    this.reservaUsuario.comuna = newValue.label;
  }
  @Watch("selectSexoMascota")
  private sexoMascotaWatcher(newValue: any) {
    this.reservaUsuario.sexo_mascota = newValue.value;
  }
  @Watch("selectEspecie")
  private especieMascotaWatcher(newValue: any) {
    this.reservaUsuario.tipo_mascota = newValue.value;
  }

  private mounted() {
    this.getPeriodo();
    this.getComunas();
  }
  private getPeriodo() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("formulario/esterilizacion/periodo")
      .then((res: any) => {
        if (res.data.cupo_felinos >= 1) {
          this.cupoFelinos = true;
        } else {
          this.cupoFelinos = false;
        }
        if (res.data.cupo_caninos >= 1) {
          this.cupoCaninos = true;
        } else {
          this.cupoCaninos = false;
        }
        let fecha_inicio = new Date(res.data.fecha_inicio);
        let fecha_termino = new Date(res.data.fecha_termino);
        let fecha_hoy = new Date();
        if (fecha_hoy < fecha_inicio || fecha_hoy > fecha_termino) {
          this.fechaVigente = false;
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
      });
  }
  private getComunas() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("comunas")
      .then((res: any) => {
        res.data.forEach((city: any) => {
          this.cityList.push({
            value: city.id,
            label: city.nombre
          });
        });
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.cityList = [];
        this.$q.loading.hide();
      });
  }
  private reservar() {
    if (
      !this.validateRut(this.reservaUsuario.rut) ||
      this.reservaUsuario.rut.trim() == ""
    ) {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor ingrese su Rut",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (this.reservaUsuario.nombre.trim() == "") {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor ingrese su nombre",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (this.reservaUsuario.apellido_paterno.trim() == "") {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor ingrese su apellido paterno",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (this.reservaUsuario.telefono_1.trim() == "") {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor ingrese su teléfono principal",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (this.reservaUsuario.telefono_1.trim().length < 9) {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor ingrese un teléfono principal válido (9 dígitos)",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (this.reservaUsuario.telefono_2.trim() != "") {
      if (this.reservaUsuario.telefono_2.trim().length < 9) {
        this.$swal({
          icon: "warning",
          title: "Campos obligatorios",
          text: "Por favor ingrese un teléfono secundario válido (9 dígitos)",
          confirmButtonText: "Aceptar"
        });
        return;
      }
      if (
        this.reservaUsuario.telefono_2.trim() ==
        this.reservaUsuario.telefono_1.trim()
      ) {
        this.$swal({
          icon: "warning",
          title: "Campos obligatorios",
          text:
            "Por favor ingrese números de teléfono principal y secundario diferentes",
          confirmButtonText: "Aceptar"
        });
        return;
      }
    }
    if (this.reservaUsuario.email.trim() == "") {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor ingrese su correo",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (!this.validEmail(this.reservaUsuario.email)) {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor un correo válido",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (this.reservaUsuario.direccion.trim() == "") {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor ingrese su dirección",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (this.reservaUsuario.comuna.trim() == "") {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor ingrese su comuna",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (this.reservaUsuario.nombre_mascota.trim() == "") {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor ingrese el nombre de la mascota",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (this.reservaUsuario.edad_mascota.trim() == "") {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor ingrese la edad de la mascota",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (!this.reservaUsuario.sexo_mascota) {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor seleccione el sexo de la mascota",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (!this.reservaUsuario.tipo_mascota) {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor seleccione la especie de la mascota",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (!this.cupoCaninos && this.reservaUsuario.tipo_mascota == 1) {
      this.$swal({
        icon: "warning",
        title: "Formulario Inscripción",
        text:
          "Lo sentimos, no hay cupos disponibles para la especie seleccionada",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (!this.cupoFelinos && this.reservaUsuario.tipo_mascota == 2) {
      this.$swal({
        icon: "warning",
        title: "Formulario Inscripción",
        text:
          "Lo sentimos, no hay cupos disponibles para la especie seleccionada",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (this.reservaUsuario.raza_mascota.trim() == "") {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor ingrese la raza de la mascota",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (this.customModel == "no" || !this.customModel) {
      this.$swal({
        icon: "warning",
        title: "Campos obligatorios",
        text: "Por favor debe aceptar los términos y condiciones",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    if (!this.reservaUsuario.apellido_materno) {
      this.reservaUsuario.apellido_materno = "";
    }

    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .post("/formulario/esterilizacion", this.reservaUsuario)
      .then((res: any) => {
        this.reservaCompleta = true;
        this.$swal({
          icon: "success",
          title: "Inscripción Completada",
          text: "Su inscripción ha sido realizada correctamente.",
          confirmButtonText: "Aceptar"
        }).then(result => {
          // this.$q.loading.show({
          //   delay: 100 // ms
          // });
          // this.$router.go(0);
        });
        this.$q.loading.hide();
      })
      .catch(err => {
        const message = err.response.data.message;
        this.reservaCompleta = false;
        if (message == "INVALID_PARAM_RUT") {
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error",
            text: "Por favor ingrese un rut válido.",
            confirmButtonText: "Aceptar"
          });
        } else if (message == "MAX_REGISTRO_RUT") {
          this.$swal({
            icon: "warning",
            title: "Ha ocurrido un error",
            text:
              "El Rut ingresado ya cuenta con el máximo de inscripciones permitidas (2).",
            confirmButtonText: "Aceptar"
          });
        } else if (message == "INVALID_PARAM_NOMBRE") {
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error",
            text: "Por favor ingrese un nombre.",
            confirmButtonText: "Aceptar"
          });
        } else if (message == "INVALID_PARAM_APELLIDO_PATERNO") {
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error",
            text: "Por favor ingrese un apellido paterno.",
            confirmButtonText: "Aceptar"
          });
        } else if (message == "INVALID_PARAM_TELEFONO_1") {
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error",
            text: "Por favor ingrese un teléfono principal.",
            confirmButtonText: "Aceptar"
          });
        } else if (message == "INVALID_PARAM_TELEFONO_2") {
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error",
            text: "Por favor ingrese un teléfono secundario.",
            confirmButtonText: "Aceptar"
          });
        } else if (message == "INVALID_PARAM_EMAIL") {
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error",
            text: "Por favor ingrese un correo.",
            confirmButtonText: "Aceptar"
          });
        } else if (message == "NO_CUPO_TIPO") {
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error",
            text: "Ya no hay cupos disponibles para la especie seleccionada.",
            confirmButtonText: "Aceptar"
          });
        } else if (message == "REGISTRADO") {
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error",
            text: "La mascota ya se encuentra registrada.",
            confirmButtonText: "Aceptar"
          });
        } else if (message == "INVALID_PERIODO") {
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error",
            text: "Ha concluido el plazo para realizar la inscripción.",
            confirmButtonText: "Aceptar"
          });
        } else {
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error",
            text: "Ha ocurrido un error, por favor intente nuevamente.",
            confirmButtonText: "Aceptar"
          });
        }
        this.$q.loading.hide();
      });
  }
  private onVerify(response) {
    this.reservar();
  }
  private onExpired() {
    this.$swal({
      icon: "warning",
      title: "Formulario Inscripción",
      text: "Por favor, recargue la página",
      confirmButtonText: "Aceptar"
    });
  }
  private onError() {
    this.$swal({
      icon: "warning",
      title: "Formulario Inscripción",
      text: "Ha ocurrido un error, intente más tarde.",
      confirmButtonText: "Aceptar"
    });
  }
  private rutSanitize(rut: string) {
    const temp = rut.replace(/\./g, "").replace(/-/g, "");
    const rutNumber = temp.substring(0, temp.length - 1);
    const dv = temp.substring(temp.length - 1);
    return `${rutNumber}-${dv}`;
  }
  private validEmail(email: any) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(email) === true) {
      return true;
    } else {
      return false;
    }
  }
  public validateRut(newValue: string) {
    var texto = newValue;
    var tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    var largo = texto.length;
    if (largo < 2) {
      this.validRut = false;
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        this.validRut = false;
        return false;
      }
    }
    var invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    var dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    var cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      //alert("i=[" + i + "] j=[" + j +"]" );
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      this.validRut = true;
      return true;
    }
    this.validRut = false;
    return false;
  }
  public revisarDigito(dvr: string) {
    var dv = dvr + "";
    if (
      dv != "0" &&
      dv != "1" &&
      dv != "2" &&
      dv != "3" &&
      dv != "4" &&
      dv != "5" &&
      dv != "6" &&
      dv != "7" &&
      dv != "8" &&
      dv != "9" &&
      dv != "k" &&
      dv != "K"
    ) {
      return false;
    }
    return true;
  }
  public revisarDigito2(crut: string) {
    var largo = crut.length;
    if (largo < 2) {
      return false;
    }
    if (largo > 2) var rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    var dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);
    if (rut == null || dv == null) return 0;
    var dvr = "0";
    var suma = 0;
    var mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + parseInt(rut.charAt(i), 10) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    var res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      var dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }
  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }
  private filterFnComuna(val, update, abort) {
    update(() => {
      const needle = val.toLowerCase();
      this.comunaOptions = this.cityList.filter(
        v => v.label.toLowerCase().indexOf(needle) > -1
      );
    });
  }
  private cerrarTerminos(termsAceptar) {
    if (termsAceptar == true) {
      this.customModel = "si";
      this.bTerminos = false;
    } else if (termsAceptar == false) {
      this.customModel = "no";
      this.bTerminos = false;
    } else {
      this.bTerminos = false;
    }
  }
}
